import React from 'react';
//import logo from './s1iwy.JPG';
import {
 Flex,
 // Heading,
Image,
//  View,
} from "@aws-amplify/ui-react";

function Home (){

 // if ((window.matchMedia("(orientation: portrait)").matches)) {var tmpgap = '1rem'} else {tmpgap = '0rem'};
 //var tmpgap = '0rem';

	return (<>
	<div style={{   color: '#FECD45' ,    backgroundColor: 'black'   }}>

<div className='navBarBox' >   




<Flex
direction="row"
justifyContent="space-between"
alignItems = "center"
textAlign="center"
gap="0"
maxWidth='100vw'
//minWidth='100vw'
>


<Flex
justifyContent="start"
direction="row"
minWidth='24vw'
maxWidth='24vw'
//boxShadow= "inset 0 0 0 1px grey"
paddingLeft='3vw'
gap="calc(3px + 1vw)"
wrap="wrap"
>


</Flex>


<Flex
direction="column"
textAlign="center"
//boxShadow= "inset 0 0 0 1px red"
gap="0"
//maxWidth='40vw'
minHeight='13vw'
>
 <span className = "navBarTitle" style={{ textDecoration: 'none' }} to="/">NANO<span  style={{ color: '#2568FB' }}  >CLOUD</span>SERVERS</span>




 <span  className='Mid-bar' >NANO SERVER HELP support@nanocloudservers.com</span>

 </Flex>


 <Flex
 justifyContent="end"
 direction="row"
 minWidth='24vw'
 maxWidth='24vw'
  //boxShadow= "inset 0 0 0 1px grey"
 paddingRight='3vw'
  wrap="wrap"
  gap="calc(3px + 1vw)"
   >
	
</Flex>
	
</Flex>

</div>
	


	
	
	
	
	

	





<Flex
	direction="row"
	justifyContent="center"
	alignItems = "center"
	textAlign="center"
	
>





<div className='helpContentSmall2' >
<br></br>
	






	<Flex 

textAlign='center'
justifyContent="center"
alignItems="center"
><span className = 'helpContentHedder'>GENERAL USE</span></Flex>
<br></br>
-The status of the cloud server will be shown as STARTING, RUNNING, STOPPING or STOPPED<br></br>
-To start up the cloud server, use the START button<br></br>
-To connect to the cloud server via RDP, use the CONNECT button<br></br>
-Please wait 4-8min for the CONNECT to become available after using the START Button<br></br>
-To shutdown the cloud server, use the STOP button<br></br>
<br></br>

<Flex 

textAlign='center'
justifyContent="center"
alignItems="center"
><span className = 'helpContentHedder'>ADDITIONAL FEATURES</span></Flex>
<br></br>
-To Backup the server, make sure it is STOPPED and then use the OPTIONS button followed by the BACKUP button.<br></br>
-To Restore the server, make sure it is STOPPED and then use the OPTIONS button followed by the RESTORE button.<br></br>
-To Reset the server, make sure it is STOPPED and then use the OPTIONS button followed by the RESET button.<br></br>
<br></br>





		<Flex 

textAlign='center'
justifyContent="center"
alignItems="center"
><span className = 'helpContentHedder'>MAC SPECIFIC INSTRUCTIONS</span></Flex>
<br></br>
-Before connecting, please download and install 'Microsoft Remote Desktop' (or similar app) from the Apple App Store.<br></br>
-Once installed proceed to the FIRST TIME CONNECTION section.<br></br>
<br></br>






<Flex 

textAlign='center'
justifyContent="center"
alignItems="center"
><span className = 'helpContentHedder'>PC SPECIFIC INSTRUCTIONS</span></Flex>
<br></br>
-Windows 10 and later is already ready to go! Proceed to the FIRST TIME CONNECTION section.<br></br>
<br></br>



<Flex 

textAlign='center'
justifyContent="center"
alignItems="center"><span className = 'helpContentHedder'>FIRST TIME CONNECTION / RDP CONNECT HELP</span></Flex>
<br></br>
-Once the server is up and RUNNING, the CONNECT button will appear.<br></br>
-Clicking this button will open an RDP INFO pop-up showing the IP Address, User Name, and Password for your server.<br></br>
-You can manually connect with an RDP application using the information shown or proceed with the instructions to automatically connect<br></br>
---<br></br>
-Click the 'COPY' button on the RDP pop-up to store the current password to the clipboard.<br></br>
-Click the 'Download' button on the RDP pop-up to DOWNLOAD an RDP Connection Shortcut file. <br></br> 
-Once downloaded, open the RDP file to automatically launch the default Remote Desktop app with the current IP Address and User Name.<br></br>
-NOTE: Some browsers will alert on the RDP Shortcut file, however it is only a plain text file that contains the server login information.<br></br>
-The 'Remote Desktop Connection' app will start and inform you about the connection being made.  Click 'Connect' to continue.<br></br>
-When prompted for a password, paste it into the password box (use CTRL-V to PASTE) and click 'OK' to proceed.<br></br>
-A final message will appear about the identity and certificate of the remote computer.  Click "Yes" to finish. <br></br>
-The Desktop of the remote server with be displayed within the Remote Desktop application.<br></br>
<br></br>


<Flex 
direction='column'
textAlign='center'
justifyContent="center"
alignItems="center">
<span className = 'helpContentHedder'>OFFICAL MICROSOFT REMOTE DESKTOP HELP</span>
<a 
  href="https://support.microsoft.com/en-us/windows/how-to-use-remote-desktop-5fe128d5-8fb1-7a23-3b8a-41e636865e8c"
  target="_blank"
  rel="noopener noreferrer"
  className='navBarLink3'
  style={{ textDecoration: 'none', fontWeight: "bold" , padding: '1vw' }}
   >How to use Remote Desktop</a>

</Flex>

<br></br><br></br>






	</div>
	

	</Flex>


</div>




 </>
		
	
	)
}

export default Home;
