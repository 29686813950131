import React from 'react';
//import logo from './s1iwy.JPG';
import {
 Flex,
 // Heading,
Image,
//  View,
} from "@aws-amplify/ui-react";
import { Link } from 'react-router-dom';
import TextTransition, { presets } from 'react-text-transition';
//import background from "../background3.jpeg";
//import background from "./ncs1s.jpg";

//import background from "../ncs5a.jpg";
import img1 from "../ncs10-1a.png";
import img2 from "../ncs10-2a.png";




function Home (){


	const TEXTS1a = ['',"What are the nano server's specifications?"];
	const TEXTS1b = ['',"Hardware Specifications (100% Sustained Usage) - 2x vCPU at 500Mhz, 1GB RAM, 30GB SSD, 64Mbps Network I/O"];

	const TEXTS2a = ['',"What operating system does the server come with?"];
	const TEXTS2b = ['',"Windows Server 2022 with full control over the OS, firewall and installed software"];

	const TEXTS3a = ['',"What can a nano server be used for?"];
	const TEXTS3b = ['',"Ideal for small site WEB/HTTP, FTP, SMTP/IMAP, IoT automation, development, educational or any low utilization / high availability project"];
	

	const TEXTS4a = ['',"What is the expected nano server up time?"];
	const TEXTS4b = ['',"NCS is backed by Amazon Cloud Services which is committed to providing 99.5% or greater service availability"];

	const TEXTS5a = ['',"Where are the nano servers located?"];
	const TEXTS5b = ['',"The datacenters are located the US West (Oregon) and US East (Virginia) areas"];

	const TEXTS6a = ['',"How do I configure my nano server?"];
	const TEXTS6b = ['',"Connect to the server with any RDP Compatible App and use it like your own desktop with Full Remote Access"];

	const TEXTS7a = ['',"How soon will my nano server ready?"];
	const TEXTS7b = ['',"Be up and running within minutes of completing service payment via PayPal"];

   
	const [index, setIndex] = React.useState(0);


	React.useEffect(() => {
		const intervalId = setInterval(
	  () => setIndex((index) =>  1),
		  100, // every 3 seconds
		);
		return () => clearTimeout(intervalId);
	  }, []);
	 

	return (<>
	
	<div className = "Body" >

	<div className='navBarBox' >   
	

	
	
<Flex
	direction="row"
	justifyContent="space-between"
	alignItems = "center"
	textAlign="center"
	gap="0"
	maxWidth='100vw'
	//minWidth='100vw'
>


<Flex
 justifyContent="start"
direction="row"
minWidth='24vw'
maxWidth='24vw'
//boxShadow= "inset 0 0 0 1px grey"
paddingLeft='2vw'
gap= "1.5vw"
wrap="wrap"
>


<Link className='navBarLink3' style={{ textDecoration: 'none' }} to="/">HOME</Link>
<span hidden className='navBarLink3' style={{ textDecoration: 'none' }} to="/help">CONTACT</span>

</Flex>


<Flex
	direction="column"
	textAlign="center"
	//boxShadow= "inset 0 0 0 1px red"

	//maxWidth='40vw'
	minHeight='13vw'
	gap="0"
	>
	 <Link className = "navBarTitle" style={{ textDecoration: 'none' }} to="/">NANO<span  style={{ color: '#2568FB' }}  >CLOUD</span>SERVERS</Link>




	 <span  className='Mid-bar' style={{fontSize:'3.8vw' }} >FAQ</span>

	 </Flex>


	 <Flex
	 justifyContent="end"
	 direction="row"
	 minWidth='24vw'
	 maxWidth='24vw'
	  //boxShadow= "inset 0 0 0 1px grey"
	 paddingRight='2vw'
	  wrap="wrap"
	  gap="1.5vw"
	   >
<a className="navBarLink3blue"
          href="https://app.nanocloudservers.com/signup"
          target="_blank"
          rel="noopener noreferrer"
		  style={{ textDecoration: 'none' }}
        >SIGN UP</a>
		<a className="navBarLink3"
          href="https://app.nanocloudservers.com"
          target="_blank"
          rel="noopener noreferrer"
		  style={{ textDecoration: 'none' }}
        >LOG IN</a>
		
</Flex>
		
</Flex>

</div>


	
	
	
	
	

	









<div className='pageContentA'>










<Flex
			direction="column"
			justifyContent="center"
			alignItems="center"
			textAlign="center"
			gap="0"
			//marginTop="2vh"
			//fontSize='10px'
		//	marginRight='25vw'
			minHeight='34.95vw'
		>









			
		<Flex
			direction="column"
			justifyContent="center"
			//alignItems="left"
			//textAlign="left"
			//gap="calc(55px - 6vw)"
			gap = "calc(1.7vw + 2px)"
			wrap='wrap'
			//fontSize='calc(4.8vh)'
			// /boxShadow='inset 0 0 0 1px'
			backgroundColor="transparent"
			//marginTop="0.5vw"
			//marginLeft='8vw'
		paddingBottom='4px'
//			color='#FECD45'
		>

		<span className = "pageContent2"  ><TextTransition springConfig={presets.default}>{TEXTS1a[index % TEXTS1a.length]}</TextTransition></span>
		<span className = "pageContentSmallLeft"  ><TextTransition springConfig={presets.default}>{TEXTS1b[index % TEXTS1b.length]}</TextTransition></span>

		<span className = "pageContent2"  ><TextTransition springConfig={presets.default}>{TEXTS2a[index % TEXTS2a.length]}</TextTransition></span>
		<span className = "pageContentSmallLeft"  ><TextTransition springConfig={presets.default}>{TEXTS2b[index % TEXTS2b.length]}</TextTransition></span>


		<span   className = "pageContent2"  ><TextTransition springConfig={presets.default}>{TEXTS3a[index % TEXTS3a.length]}</TextTransition></span>
		<span className = "pageContentSmallLeft" style = {{paddingBottom:'2vw'}}  ><TextTransition springConfig={presets.default}>{TEXTS3b[index % TEXTS3b.length]}</TextTransition></span>

		<span  className = "pageContent2" ><TextTransition springConfig={presets.default}>{TEXTS4a[index % TEXTS4a.length]}</TextTransition></span>
		<span className = "pageContentSmallLeft" ><TextTransition springConfig={presets.default}>{TEXTS4b[index % TEXTS4b.length]}</TextTransition></span>		
		
		<span  className = "pageContent2" ><TextTransition springConfig={presets.default}>{TEXTS5a[index % TEXTS5a.length]}</TextTransition></span>
		<span className = "pageContentSmallLeft" ><TextTransition springConfig={presets.default}>{TEXTS5b[index % TEXTS5b.length]}</TextTransition></span>		

		<span  className = "pageContent2" ><TextTransition springConfig={presets.default}>{TEXTS6a[index % TEXTS6a.length]}</TextTransition></span>
		<span className = "pageContentSmallLeft" ><TextTransition springConfig={presets.default}>{TEXTS6b[index % TEXTS6b.length]}</TextTransition></span>
		
		<span  className = "pageContent2" ><TextTransition springConfig={presets.default}>{TEXTS7a[index % TEXTS7a.length]}</TextTransition></span>
		<span className = "pageContentSmallLeft" ><TextTransition springConfig={presets.default}>{TEXTS7b[index % TEXTS7b.length]}</TextTransition></span>
		
	
		</Flex>
		













		</Flex>





	</div>








	







	</div>
	
 </>
		
	
	)
}

export default Home;
