import React, { useEffect } from 'react';
import "./App.css";
import "@aws-amplify/ui-react/styles.css";
import {
 Flex,
//  Heading,
  Image,
//  View,
} from "@aws-amplify/ui-react";
import { Routes, Route} from 'react-router-dom';
import Home from './component/home';
//import About from './component/about';
import Tos from './component/tos';
import Help from './component/help';
import Faq from './component/faq';
import PrivPol from './component/privpol';
//import Ssllogo from "./th.jpg";
//import Smblogo from "./Badge_3.png";
//import background from "./b11b.png";
//import logo from './logo.svg';
//import background from "./ncs5a.jpg";
import img1 from "./ncs10-1a.png";
import img2 from "./ncs10-2a.png";
import img3 from "./ncs10-3.jpg";

function App() {

	useEffect(() => {
		document.title = 'Nano Cloud Servers';
	  }, []);


  if ( window.location.pathname === '/help/'  ) {
    var tmp=true
  } else if ( window.location.pathname === '/tos/'  ) {
    tmp=true
  } else if ( window.location.pathname === '/privpol/'  ) {
    tmp=true
  } else {
    tmp= false 
  }



  return (


  
     
<div className="App">

<div className= "Wrapper">

		  <div className= "Main">
		  
{// backgroundImage: `url(${background})`
}


    
	
	
		
	<Routes>
				<Route exact path='/' element={< Home />}></Route>

				<Route path='/tos' element={< Tos />}></Route>
				<Route path='/help' element={< Help />}></Route>
				<Route path='/faq' element={< Faq />}></Route>
				<Route path='/privpol' element={< PrivPol />}></Route>
				<Route path="*" element={<NotFound />}></Route>
		</Routes>
		
		
	
		</div>




<div hidden = {tmp} className = "fadeshow1">

		<div className="div-bgr" 	>

<Image
  alt="NCS1img"
  src={img2}
  paddingTop='calc(1.16vw - 6px)'
  //objectFit="initial"
  //objectPosition="50% 50%"
  //objectFit="cover"
  backgroundColor="initial"
  //height='65vh'
  width='22.93vw'
  opacity="100%"
	
  //onClick={() => alert('📸 Say cheese!')}
/>
			</div>

			<div className="div-bgl" 		>

<Image
  alt="NCS2img"
  src={img1}
  //objectFit="initial"
  //objectPosition="50% 50%"
  //objectFit="cover"
  backgroundColor="initial"
  //height='45vh'
  paddingTop='calc(1.16vw - 6px)'
  width='5.63vw'
  opacity="100%"

  //onClick={() => alert('📸 Say cheese!')}
/>
			</div>

			</div >

			<div className = "fadeshow2">


			<div hidden = {tmp} className="div-bgb" 	>


			
<Image
  alt="NCS3img"
 src={img3}
  objectFit="cover"
  objectPosition="bottom"
  opacity="100%"
//backgroundColor='red'
></Image>
</div>


</div>

</div>
</div>


  );
};

export const NotFound = () => {
	
	return(
		
		<div >
			<Flex
			color="white"
			paddingTop="10vh"
			
			marginLeft="15vw"
			marginRight="15vw"
			fontSize="10vh"
			>
				
			404 - The content you are looking is not available			
			</Flex>
			</div>
	
	)
	
  }

//export default withAuthenticator(App);
export default App;