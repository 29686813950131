import React from 'react';
//import logo from './s1iwy.JPG';
import {
 Flex,
 // Heading,
Image,
//  View,
} from "@aws-amplify/ui-react";
import TextTransition, { presets } from 'react-text-transition';
import { Link } from 'react-router-dom';
//import background from "../background3.jpeg";
//import background from "./ncs1s.jpg";
//import background from "../ncs5a.jpg";
import img1 from "../ncs10-1a.png";
import img2 from "../ncs10-2a.png";


function Home (){

 // if ((window.matchMedia("(orientation: portrait)").matches)) {var tmpgap = '1rem'} else {tmpgap = '0rem'};
 //var tmpgap = '0rem';

 const TEXTS1 = ['','Full Windows Server'];
 const TEXTS2 = ['',' in the Cloud!'];
 const TEXTS3a = ['','Fast and Simple Server Backups'];
 const TEXTS3b = ['','Easy Backup Restore and Server Reset'];
 const TEXTS3c = ['','RDP Access and Static IP Included'];

 const [index, setIndex] = React.useState(0);
 //const [index2, setIndex2] = React.useState(0);

 React.useEffect(() => {
   const intervalId = setInterval(
 () => setIndex((index) =>  1),
	 100, // every 3 seconds
   );
   return () => clearTimeout(intervalId);
 }, []);

 //React.useEffect(() => {
//	const intervalId = setInterval(
//	  () => setIndex2((index2) => index2 + 1),
//	  3000, // every 3 seconds
//	);
//	return () => clearTimeout(intervalId);
 // }, []);

	return (<>
	
	<div className = "Body" >

	

	<div className='navBarBox' >   
	

	
	
<Flex
	direction="row"
	justifyContent="space-between"
	alignItems = "center"
	textAlign="center"
	gap="0"
	maxWidth='100vw'
	//minWidth='100vw'
>


<Flex
 justifyContent="start"
direction="row"
minWidth='24vw'
maxWidth='24vw'
//boxShadow= "inset 0 0 0 1px grey"
paddingLeft='2vw'
gap="1.5vw"
wrap="wrap"
>


<Link className='navBarLink3' style={{ textDecoration: 'none' }} to="/faq">FAQ</Link>
<span hidden className='navBarLink3' style={{ textDecoration: 'none' }} to="/help">CONTACT</span>

</Flex>


<Flex
	direction="column"
	textAlign="center"
	//boxShadow= "inset 0 0 0 1px red"
	gap="0"
	//maxWidth='40vw'
	minHeight='13vw'
	>
	 <Link className = "navBarTitle" style={{ textDecoration: 'none' }} to="/">NANO<span  style={{ color: '#2568FB' }}  >CLOUD</span>SERVERS</Link>




		<span  className='Mid-bar' >Low-Cost Windows Nano VPS $16.99 - $19.99/month</span>

	 </Flex>


	 <Flex
	 justifyContent="end"
	 direction="row"
	 minWidth='24vw'
	 maxWidth='24vw'
	  //boxShadow= "inset 0 0 0 1px grey"
	 paddingRight='2vw'
	  wrap="wrap"
	  gap="1.5vw"
	   >
<a className="navBarLink3blue"
          href="https://app.nanocloudservers.com/signup"
          target="_self"
          rel="noopener noreferrer"
		  style={{ textDecoration: 'none' }}
        >SIGN UP</a>
		<a className="navBarLink3"
          href="https://app.nanocloudservers.com"
          target="_self"
          rel="noopener noreferrer"
		  style={{ textDecoration: 'none' }}
        >LOG IN</a>
		
</Flex>
		
</Flex>

</div>








<div className='pageContentH'>




<Flex
			direction="column"
			justifyContent="center"
			alignItems="center"
			textAlign="center"
			gap="8.7vw"
			marginTop="2vw"
			
			
			//fontSize='10px'
			//marginRight='25vw'
		>



<Flex		
			direction="column"
			justifyContent="center"
			alignItems="center"
			//textAlign="right"
			gap="0"
			fontSize='5.9vw'		
			color='inherit'	
			//opacity='.85'
			//marginLeft='10vw'
			//marginRight='0vw'
			//boxShadow='inset 0 0 0 1px'
			>
			
      
    
	
			<span className = 'pageContentHedderH'><TextTransition springConfig={presets.default}>{TEXTS1[index % TEXTS1.length]}</TextTransition></span>
			</Flex>

			<Flex
			direction="column"
			justifyContent="center"
			alignItems="center"
			gap="0"
			fontSize='5.9vw'		
			//opacity='.85'
			//marginRight='2vw'
			//boxShadow='inset 0 0 0 1px'
			>

			<span  className = 'pageContentHedderLowerH'><TextTransition springConfig={presets.default}>{TEXTS2[index % TEXTS2.length]}</TextTransition></span>
			</Flex>
		
		
		<Flex
			direction="column"
			justifyContent="center"
			alignItems="center"
			textAlign="center"
			gap="0"
			fontSize='2vw'
			marginLeft='7vw'
			paddingLeft='5vw'
			paddingTop="0.0vw"
			
		>

		<span className = "boxBorder2">
		<Flex
			direction="column"
			justifyContent="left"
			alignItems="left"
			textAlign="left"
			//gap="calc(55px - 6vw)"
			//gap = ".15vh"
		//	wrap='wrap'
			fontSize='2.43vw'
			//boxShadow='inset 0 0 0 1px'
			//paddingLeft="4vh"
			//paddingRight="12vw"
		//	marginLeft='0vw'
		minHeight='11.3vw'
		
		
//			color='#FECD45'
		>

			<Flex
			direction="column"
			gap='3.7vw'
			minWidth='43.6vw'
			paddingLeft= "3.2vw" 
			>
		<span   > <TextTransition springConfig={presets.default}>{TEXTS3a[index % TEXTS3a.length]}</TextTransition></span>
		<span  > <TextTransition springConfig={presets.default}>{TEXTS3b[index % TEXTS3b.length]}</TextTransition></span>
		<span   > <TextTransition springConfig={presets.default}>{TEXTS3c[index % TEXTS3c.length]}</TextTransition></span>

		</Flex>
		
		</Flex>


		</span>	
		

		


		</Flex>

	  
		</Flex>

	





	</div>
	


	

</div>





 </>
		
	
	)
}

export default Home;
